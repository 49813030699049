// @flow
import * as React from "react";
import {
  LinearProgress,
  Table as TableImpl,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import { TableVirtuoso } from "react-virtuoso";
import { styled } from "@mui/material/styles";
import type { DataTableProps, RowId } from "./base";
import { TableRootPaper, TableRow, useDataTableParams } from "./base";
import TableToolbar from "./TableToolbar";

const Table = styled(TableImpl)`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
`;

const withSlotProps = (
  Component: React.ComponentType<any>,
  slotProps: Object
) =>
  React.forwardRef<any, any>((props: Object, ref: React.Ref<any>) => (
    <Component ref={ref} {...slotProps} {...props} />
  ));

const getVirtuosoTableComponents = <DataItem, K: RowId = RowId>(
  slotProps: DataTableProps<DataItem, K>["slotProps"]
): Object => ({
  Scroller: withSlotProps(TableContainer, slotProps?.tableContainer),
  Table: withSlotProps(Table, slotProps?.table),
  TableHead,
  TableRow: withSlotProps(TableRow, slotProps?.tableRow),
  TableBody,
});

const VirtualDataTable = <DataItem, K: RowId = RowId, CellProps: Object = {}>({
  slotProps,
  actions,
  loading,
  ...props
}: DataTableProps<DataItem, K, CellProps>): React.Node => {
  const params = useDataTableParams(props);
  const components = React.useMemo(
    () => getVirtuosoTableComponents(slotProps),
    [slotProps]
  );
  return (
    <TableRootPaper {...slotProps?.paper}>
      {loading && <LinearProgress />}
      {slotProps?.toolbar && (
        <TableToolbar
          selection={params.selection}
          actions={actions}
          {...slotProps.toolbar}
        />
      )}
      <TableVirtuoso
        data={params.rows}
        components={components}
        fixedHeaderContent={params.getFixedHeaderContent}
        itemContent={params.getRowContent}
      />
    </TableRootPaper>
  );
};

export default VirtualDataTable;
