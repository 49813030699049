// @flow
import * as React from "react";
import type { LockState, LockActions } from "./lib/lock.state";
import { useLockState } from "./lib/lock.state";
import type { MappingActions, MappingState } from "./lib/mapping.reducer";
import {
  makeMappingActions,
  mappingReducer,
  useMappingReducer,
} from "./lib/mapping.reducer";
import type { SequenceActions, SequenceState } from "./lib/sequence.reducer";
import {
  makeSequenceActions,
  sequenceReducer,
  useSequenceReducer,
} from "./lib/sequence.reducer";
import type { Post } from "../../models/post.model";
import type { AttachmentUploadProgress } from "../../models/attachment.model";
import type { ModelID } from "../../types";
import { byAccountId } from "../../models/base.model";
import type { InitActions, InitState } from "./lib/init.state";
import { useInitState } from "./lib/init.state";
import { getTime } from "../../models/post.model";
import type { PostBuilderInitial } from "../../contexts/postBuilder";

export type PostActions = {
  ...LockActions,
  ...InitActions,
  posts: {
    ...SequenceActions<Post>,
    deleteAllPostsInAccount: (ModelID) => void,
    extendAndSort: (posts: Post[]) => void,
  },
  uploads: MappingActions<AttachmentUploadProgress>,
  onSetBuilderPayload: (?PostBuilderInitial) => void,
};

export type PostState = {
  ...LockState,
  ...InitState,
  posts: SequenceState<Post>,
  uploads: MappingState<AttachmentUploadProgress>,
  builderPayload: ?PostBuilderInitial,
};

const postsReducer = sequenceReducer<Post>({
  key: "id",
});
const uploadsReducer = mappingReducer<AttachmentUploadProgress>({
  key: "key",
});

export const usePostReducer = (): [PostState, PostActions] => {
  // Reducers first
  const [posts, postsDispatch] = useSequenceReducer(postsReducer);
  const [uploads, uploadsDispatch] = useMappingReducer(uploadsReducer);
  const [locked, lockActions] = useLockState();
  const [initialised, initActions] = useInitState();
  // Builder payload is only used to carry builder payload
  // from quick post to psot editor.
  const [builderPayload, setBuilderPayload] =
    React.useState<?PostBuilderInitial>(null);

  // Then make actions
  const postsActions = React.useMemo(
    () => makeSequenceActions(postsDispatch),
    [postsDispatch]
  );

  const uploadsActions = React.useMemo(
    () => makeMappingActions(uploadsDispatch),
    [uploadsDispatch]
  );

  const actions = React.useMemo(
    () => ({
      onSetBuilderPayload: setBuilderPayload,
      posts: {
        ...postsActions,
        deleteAllPostsInAccount: (accountId: ModelID) =>
          postsActions.removeBy(byAccountId(accountId)),
        extendAndSort: (posts: Post[]) => {
          postsActions.extend(posts);
          postsActions.sort((post) => -(getTime(post)?.unix() ?? 0));
        },
      },
      uploads: uploadsActions,
      ...lockActions,
      ...initActions,
    }),
    [postsActions, uploadsActions, lockActions, initActions]
  );

  return [{ posts, uploads, locked, initialised, builderPayload }, actions];
};
