// @flow
import * as React from "react";
import Calendar from "./Calendar";
import moment from "moment";
import useFilteredPosts from "../../../../hooks/useFilteredPosts";
import { enrichPostsForMainCalendar } from "../../../../util/calendar.util";
import type { BobcaatAccount } from "../../../../models/account.model";
import type { Portfolio } from "../../../../models/portfolio.model";
import type { Timezone } from "../../../../models/timezone.model";
import { useNavigate } from "react-router-dom";
import type {
  CalendarPost,
  CalendarView,
} from "../../../../util/calendar.util";
import routes, { getRoutePath } from "../../../../config/routes.config";
import { useStore } from "../../../../store/store";
import { localWarning } from "../../../../models/alerts.model";
import * as postService from "../../../../services/post.service";
import { makeEditPostParamsFromPost } from "../../../../services/post.service";
import PleaseWaitProgress from "../../../feedback/PleaseWaitProgress";
import { hasTime } from "../../../../models/post.model";

export type Props = {
  accounts: BobcaatAccount[],
  portfolio: Portfolio,
  timezone: Timezone,
};

const CalendarContainer: React.ComponentType<Props> = ({
  timezone,
  accounts,
  portfolio,
}) => {
  const navigate = useNavigate();
  const [storeState, actions] = useStore();
  const posts = useFilteredPosts(portfolio.id);
  const [draggedPost, setDraggedPost] = React.useState<?CalendarPost>(null);
  const [view, setView] = React.useState<CalendarView>("month");
  const enrichedPosts = React.useMemo(
    () =>
      enrichPostsForMainCalendar(
        posts.filter(hasTime),
        portfolio,
        accounts,
        timezone.offset,
        draggedPost,
        view
      ),
    [posts, portfolio, accounts, draggedPost, timezone.offset, view]
  );

  if (storeState.post.locked || !storeState.post.initialised) {
    return <PleaseWaitProgress />;
  }

  const handleMovePost = ({
    start,
    event,
  }: {
    start: Date,
    event: CalendarPost,
  }) => {
    const post = event;
    const time = moment(start);
    if (post.schedule_time && !post.schedule_time.isSame(time)) {
      if (time.isBefore(moment())) {
        actions.snacks.append(
          localWarning({
            message: "CalendarSection.Calendar.scheduleInPastForbidden",
          })
        );
      } else {
        // We need to save that time while the post is being saved,
        // otherwise the post will go back to its original time in the
        // calendar.
        setDraggedPost({ ...post, start: time });
        makeEditPostParamsFromPost(
          { ...post, schedule_time: time },
          actions
        ).then(postService.editPost(actions));
      }
    }
  };

  const handleDoubleClickPost = (post: CalendarPost) => {
    navigate(
      getRoutePath(routes.app.nested.portfolios.nested.post.nested.edit, {
        portfolioId: portfolio.id,
        postId: post.id.toString(),
      })
    );
  };

  return (
    <Calendar
      posts={enrichedPosts}
      onMovePost={handleMovePost}
      onPostDblClick={handleDoubleClickPost}
      view={view}
      onChangeView={setView}
    />
  );
};

export default CalendarContainer;
