// @flow
import fromPairs from "lodash/fromPairs";
import range from "lodash/range";
import { alpha, createTheme, responsiveFontSizes } from "@mui/material";
import { BOBCAAT_PALETTE } from "./palettes.config";
import { allChannels } from "../models/channels";
import type { Theme } from "../stubs/mui/theming";
import {
  cyan,
  deepPurple,
  green,
  grey,
  indigo,
  purple,
} from "@mui/material/colors";

const opacities = range(0, 101).map((n) => n / 100);

const defaultTheme = createTheme();

/**
 * The theme of the application.
 * It should contain all semantic colors and typography definitions for the
 * app.
 */
const lightOptions = {
  // Colors
  palette: {
    mode: "light",
    ...BOBCAAT_PALETTE.palette,
    ...fromPairs(
      allChannels.map((channel) => [
        channel.slug,
        { main: channel.color, contrastText: "#fff" },
      ])
    ),
    neutral: { main: grey[800] },
    background: {
      paper: "#FFFFFF",
      default: "#FEFBF9",
      alt: "#F8F4F1",
      input: "rgba(0, 0, 0, 0.06)",
    },
    contentType: { light: purple[300], main: purple[500], dark: purple[800] },
    threads: { light: indigo[300], main: indigo[500], dark: indigo[800] },
    poll: { light: cyan[300], main: cyan[500], dark: cyan[800] },
    approval: { light: green[300], main: green[500], dark: green[800] },
    firstComment: {
      light: deepPurple[300],
      main: deepPurple[500],
      dark: deepPurple[800],
    },
  },

  // Typography
  typography: {
    useNextVariants: true,
    fontFamily: '"Raleway", sans-serif',
  },

  // Custom
  shades: {
    darker: opacities.map((o) => `rgba(0,0,0,${o})`),
    lighter: opacities.map((o) => `rgba(255,255,255,${o})`),
  },

  shape: {
    borderRadius: 8,
  },

  animations: {
    beat: "beat ease infinite",
    bounce: "bounce ease",
    floatY: "floatY ease-in-out infinite",
    spinCircle: "spin linear infinite",
    hueRotate: "hue-rotate linear infinite",
    grayscale: "grayscale ease alternate infinite",
    pulseWhite: "pulse-white ease infinite",
    pulsePrimary: "pulse-primary ease infinite",
    pulseSecondary: "pulse-secondary ease infinite",
    pulseError: "pulse-error ease infinite",
  },

  components: {
    MuiStack: {
      defaultProps: {
        spacing: 1,
      },
    },
    MuiTypography: {
      styleOverrides: {},
    },
    MuiDialog: {
      styleOverrides: {
        paperFullScreen: ({ theme }: { theme: Theme }) => ({
          backgroundColor: theme.palette.background.default,
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          fontWeight: "bold",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: "bold",
          boxShadow: "none",
        },
        containedSizeLarge: {
          textTransform: "uppercase",
          // paddingTop: defaultTheme.spacing(2),
          // paddingBottom: defaultTheme.spacing(2),
        },
        outlinedSizeLarge: {
          textTransform: "uppercase",
          // paddingTop: defaultTheme.spacing(2),
          // paddingBottom: defaultTheme.spacing(2),
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: "bold",
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiTextField: {
      defaultProps: {
        InputProps: {
          disableUnderline: true,
        },
        variant: "filled",
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: alpha(
              defaultTheme.palette.action.hover,
              defaultTheme.palette.action.hoverOpacity
            ),
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          textOverflow: "ellipsis",
          overflow: "hidden",
        },
        secondary: {
          textOverflow: "ellipsis",
          overflow: "hidden",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: "none",
        },
        head: ({ theme }: { theme: Theme }) => ({
          fontWeight: "bold",
          backgroundColor: "transparent",
          color: theme.palette.text.secondary,
        }),
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "&.Mui-readOnly fieldset": {
            border: "none",
          },
          "&.Mui-readOnly .MuiSelect-icon": { visibility: "hidden" },
          "&.Mui-readOnly .MuiNativeSelect-icon": { visibility: "hidden" },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          fontWeight: "bold",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainerVertical: {
          "& button": {
            alignItems: "flex-end",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "initial",
          opacity: 0.7,
          "&.Mui-selected": {
            opacity: 1,
          },
        },
      },
    },
    MuiDatePicker: {
      defaultProps: {
        slotProps: {
          textField: {
            InputProps: {
              disableUnderline: true,
            },
          },
        },
      },
    },
    MuiTimePicker: {
      defaultProps: {
        slotProps: {
          textField: {
            InputProps: {
              disableUnderline: true,
            },
          },
        },
      },
    },
  },

  // Constant
  const: {
    collapsedDrawerWidth: 64,
    drawerWidth: 200,
    appBarHeight: 64,
    helpDrawerWidth: 280,
    iconSize: {
      xsmall: 22,
      small: 28,
      medium: 32,
      large: 40,
      xlarge: 56,
    },
  },
};

const darkOptions = {
  ...lightOptions,
  palette: {
    ...lightOptions.palette,
    neutral: { main: grey[400] },
    background: {
      disabled: defaultTheme.palette.grey[700],
      paper: "#17191C",
      default: "#222529",
      alt: "#1d1f23",
      input: "rgba(255, 255, 255, 0.09)",
    },
    mode: "dark",
  },
};

export const lightTheme: mixed = responsiveFontSizes(createTheme(lightOptions));

export const darkTheme: mixed = responsiveFontSizes(createTheme(darkOptions));

const all = { light: lightTheme, dark: darkTheme };

export default all;
