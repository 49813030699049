// @flow
import * as React from "react";
import { PostCreatorCollapsibleSection } from "../../layout/PostCreatorSection";
import { Collapse, TextField } from "@mui/material";
import MediaCollection from "../../lib/inputs/MediaInput/MediaCollection";
import useBuilderAttachmentsToMediaItemsAdapter from "./useBuilderAttachmentsToMediaItemsAdapter";
import PostBuilderContext from "../../../contexts/postBuilder";
import { Body2 } from "../../lib/display/Text";
import useTranslate from "../../../hooks/useTranslate";
import useHeadlessMediaInput from "../../lib/inputs/MediaInput/useHeadlessMediaInput";
import {
  getAttachmentSrc,
  isAttachmentImage,
  isAttachmentPDF,
  isAttachmentVideo,
} from "../../../models/attachment.model";
import { styled } from "@mui/material/styles";
import { NeutralButton } from "../../lib/inputs/buttons";
import { RowStack } from "../../lib/layout/stacks";
import head from "lodash/head";
import type { Callback } from "../../../types";
import AttachmentsEditorDialog from "../AttachmentsEditorDialog";
import AttachmentUserTagsEditor from "../AttachmentsEditorDialog/AttachmentUserTagsEditor";
import useSwitch from "../../../hooks/useSwitch";
import type { AttachmentSrc } from "../../../models/attachment.model";
import VideoThumbnailPickerDialog from "../VideoThumbnailPickerDialog";

const DocumentTitleInput = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const AttachmentsUserTagsDialog = ({ onClose }: { onClose: Callback }) => (
  <AttachmentsEditorDialog
    title="AttachmentUserTagDialog.title"
    onClose={onClose}
    AttachmentEditorComponent={AttachmentUserTagsEditor}
  />
);

const PostAttachmentsWidget: React.ComponentType<{}> = () => {
  const { post, onUpdatePost, channel } = React.useContext(PostBuilderContext);
  const t = useTranslate();
  const [userTagsDialogOpen, showUserTagDialog, hideUserTagDialog] =
    useSwitch();
  const [videoForThumbnailDialog, setVideoForThumbnailDialog] =
    React.useState<?AttachmentSrc>(null);
  const { mediaItems, onChangeMedia, onMediaAdded } =
    useBuilderAttachmentsToMediaItemsAdapter();

  const firstAttachment = head(post.attachments);
  const featureSpecs = channel?.features;
  const contentSpecs = featureSpecs?.content;

  // Shortened up version of mediaInputProps for the crop dialog only.
  const { cropImageDialog, onSetEditedItem } = useHeadlessMediaInput({
    items: mediaItems,
    onChange: onChangeMedia,
    onMediaAdded,
  });

  return (
    <>
      {userTagsDialogOpen && (
        <AttachmentsUserTagsDialog onClose={hideUserTagDialog} />
      )}
      {videoForThumbnailDialog && (
        <VideoThumbnailPickerDialog
          onClose={() => setVideoForThumbnailDialog(null)}
          video={videoForThumbnailDialog}
          defaultOffset={post.thumbnail_video_offset}
          onValidate={(thumbnail_video_offset) =>
            onUpdatePost({ thumbnail_video_offset })
          }
        />
      )}
      <Collapse in={mediaItems.length > 0}>
        <PostCreatorCollapsibleSection
          title="global.attachments"
          defaultCollapsed={!!post.id}
          slotProps={{
            head: {
              children: mediaItems.length > 0 && (
                <Body2>
                  {t("global.countSelected", { count: mediaItems.length })}
                </Body2>
              ),
            },
          }}
        >
          <Collapse
            in={
              contentSpecs?.thumbnail?.document &&
              firstAttachment &&
              isAttachmentPDF(firstAttachment)
            }
            unmountOnExit
          >
            <DocumentTitleInput
              fullWidth
              value={post.thumbnail_title ?? ""}
              size="small"
              onChange={(e) =>
                onUpdatePost({ thumbnail_title: e.currentTarget.value })
              }
              helperText={t("PostCreator.documentTitleInputHelper")}
              label={t("PostCreator.documentTitleInputLabel")}
            />
          </Collapse>
          <MediaCollection
            items={mediaItems}
            onChange={onChangeMedia}
            onSetEditedItem={onSetEditedItem}
          />
          <RowStack justifyContent="flex-end">
            {contentSpecs?.thumbnail?.video &&
              firstAttachment &&
              isAttachmentVideo(firstAttachment) && (
                <NeutralButton
                  onClick={() =>
                    setVideoForThumbnailDialog(
                      getAttachmentSrc(firstAttachment)
                    )
                  }
                >
                  {t("PostCreator.changeVideoThumbnail")}
                </NeutralButton>
              )}
            {featureSpecs?.mediaTagging?.image &&
              firstAttachment &&
              isAttachmentImage(firstAttachment) && (
                <NeutralButton onClick={showUserTagDialog}>
                  {t("PostCreator.changeImageUserTags")}
                </NeutralButton>
              )}
          </RowStack>
        </PostCreatorCollapsibleSection>
      </Collapse>
      {cropImageDialog}
    </>
  );
};

export default PostAttachmentsWidget;
