// @flow
import * as React from "react";
import RichContentInput from "../../inputs/RichContentInput";
import useTranslate from "../../../hooks/useTranslate";
import PostBuilderContext from "../../../contexts/postBuilder";
import useInputCharLimitHelper from "../../../hooks/useInputCharLimitHelper";
import type { Poll } from "../../../models/poll.model";
import interval from "../../../lib/interval.lib";
import { getAccepts, getMaxSize } from "../../../models/channels";
import useBuilderAttachmentsToMediaItemsAdapter from "./useBuilderAttachmentsToMediaItemsAdapter";
import {
  EMPTY_SELF_REPLY,
  disableCaptionForPost,
  urlToPostAttachment,
} from "../../../models/post.model";
import type { URLStr } from "../../../types";
import type { Channel } from "../../../models/channels/channel.model";
import useSwitch from "../../../hooks/useSwitch";
import PickGifDialog from "../../lib/inputs/PickGifDialog";
import { Alert, Collapse } from "@mui/material";
import useIsMobile from "../../../hooks/useIsMobile";

const EMPTY_POLL: Poll = {
  question: "",
  duration_minutes: interval.day.as.minutes,
  // For a poll you need at least 2 options.
  options: ["", ""],
  selects: 1,
};

const usePickGifDialog = (channel: ?Channel, onSelect: (URLStr) => any) => {
  const [open, setOpen, setClose] = useSwitch();
  return [
    setOpen,
    <PickGifDialog
      open={open}
      onClose={setClose}
      asVideo={channel?.contentSpecs.media.gif?.asMP4}
      onConfirm={onSelect}
    />,
  ];
};

type Props = {
  minRows?: number,
  maxRows?: number,
};

const PostContentInputContainer: React.ComponentType<Props> = ({
  minRows = 8,
  maxRows = 20,
}) => {
  const t = useTranslate();
  const isMobile = useIsMobile();
  const { post, onSetContent, channel, onUpdatePost } =
    React.useContext(PostBuilderContext);

  const [onChangeContent, helperFactory, isError] = useInputCharLimitHelper({
    maxLength: channel?.contentSpecs.maxChars,
    onChange: onSetContent,
  });

  const { mediaItems, onChangeMedia, onMediaAdded } =
    useBuilderAttachmentsToMediaItemsAdapter();

  const handleAddGif = React.useCallback(
    (gifURL: URLStr) => {
      onUpdatePost({
        attachments: [...post.attachments, urlToPostAttachment(gifURL)],
      });
    },
    [onUpdatePost, post.attachments]
  );

  const [onOpenGifDialog, GifDialog] = usePickGifDialog(channel, handleAddGif);
  const disabledKey = disableCaptionForPost(post, channel);

  return (
    <>
      {GifDialog}
      <Collapse in={!!disabledKey} mountOnEnter unmountOnExit>
        <Alert severity="info">{t(disabledKey)}</Alert>
      </Collapse>
      <RichContentInput
        mediaInputProps={{
          items: mediaItems,
          onChange: onChangeMedia,
          onMediaAdded,
          maxFiles: channel?.contentSpecs.media.overall.maxCount ?? 5,
          accept: getAccepts(channel),
          maxSize: getMaxSize(channel),
          forceImagesAsJpeg: !!channel?.contentSpecs.media.image?.pngAsJpeg,
          disabled:
            !channel ||
            (post.poll && channel.features?.content?.polls?.pollsOrAttachments),
        }}
        fieldProps={
          !!disabledKey
            ? undefined
            : {
                autoFocus: !isMobile,
                placeholder: t("PostCreator.contentPlaceholder"),
                value: post.content,
                onChange: (e) => onChangeContent(e.target.value),
                error: isError(post.content),
                helperText: disabledKey
                  ? t(disabledKey)
                  : helperFactory(post.content),
                multiline: true,
                minRows,
                maxRows,
              }
        }
        onInsertEmoji={disabledKey ? undefined : onSetContent}
        onInsertHashtagGroup={disabledKey ? undefined : onSetContent}
        onAddPoll={
          // Poll button visible if polls supported and no current poll.
          post.poll ||
          !channel?.features?.content?.polls ||
          (channel.features?.content?.polls?.pollsOrAttachments &&
            post.attachments.length > 0)
            ? undefined
            : () => onUpdatePost({ poll: EMPTY_POLL })
        }
        onAddThread={
          // Thread button visible if Threads supported and no current Thread.
          post.self_replies.length > 0 ||
          !channel?.features?.content?.selfReplies
            ? undefined
            : () => onUpdatePost({ self_replies: [EMPTY_SELF_REPLY] })
        }
        onAddFirstComment={
          post.first_comment !== null || !channel?.features?.commentManagement
            ? undefined
            : () => onUpdatePost({ first_comment: "" })
        }
        onInsertGIF={
          !!channel?.contentSpecs.media.gif ? onOpenGifDialog : undefined
        }
      />
    </>
  );
};

export default PostContentInputContainer;
