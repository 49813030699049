// @flow
import * as React from "react";
import useTranslate from "../../hooks/useTranslate";
import type { ContentTypes } from "../../models/contentTypes.model";
import ColoredChip from "../lib/display/ColoredChip";
import { Body2 } from "../lib/display/Text";

type Props = {
  type: ContentTypes,
};

const PostStatusChip: React.ComponentType<Props> = React.memo(({ type }) => {
  const t = useTranslate();
  return (
    <ColoredChip paletteColor="contentType">
      <Body2>{t(`global.${type}`)}</Body2>
    </ColoredChip>
  );
});

export default PostStatusChip;
