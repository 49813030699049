// @flow
import type { Post } from "../models/post.model";
import type { Portfolio } from "../models/portfolio.model";
import type { BobcaatAccount } from "../models/account.model";
import { getTime } from "../models/post.model";
import { byId } from "../models/base.model";
import { findOne } from "../lib/lodashex.lib";
import moment from "moment";
import { localTimezone } from "../models/timezone.model";
import type { Moment } from "../types";

export type CalendarView = "month" | "week";
export type RestrictedCalendarPost = {
  ...Post,
  start: ?Date,
  end: ?Date,
};
export type CalendarPost = {
  ...RestrictedCalendarPost,
  account: BobcaatAccount,
  portfolio: Portfolio,
};

export const enrichPostsForMainCalendar = (
  posts: Post[],
  portfolio: Portfolio,
  accounts: BobcaatAccount[],
  timezoneOffset: number,
  draggedPost: ?CalendarPost = null,
  view: ?CalendarView = "month"
): CalendarPost[] => {
  const initialTimezone = localTimezone();
  const tzOffset = timezoneOffset - initialTimezone.offset;
  return posts.map((post) => {
    const account = findOne(accounts, byId<BobcaatAccount>(post.account_id));
    return {
      ...post,
      start: getTime(post)?.add(tzOffset, "h").toDate(),
      end: getTime(post)
        ?.add(tzOffset, "h")
        .add(view === "month" ? 1 : 120, "m")
        .toDate(),
      schedule_time:
        draggedPost && post.id === draggedPost.id
          ? moment(draggedPost.start)
          : post.schedule_time,
      account: account,
      portfolio: portfolio,
    };
  });
};

/**
 * get the targeted beginning of month for the anchor, in month view.
 */
export const getAnchorStartOfTargetMonth = (anchor: Moment): Moment =>
  anchor.date() === 1
    ? moment(anchor)
    : moment(anchor).add(1, "week").startOf("month");
