// @flow
import pick from "lodash/pick";
import values from "lodash/values";
import type { I18nKey } from "../types";
import type { Channel } from "../models/channels/channel.model";

/**
 * A problem with a post being built.
 */
export type DiagnosticError =
  | "tooManyMedia"
  | "notEnoughMedia"
  | "tooManyVideos"
  | "tooManyImages"
  | "unsupportedMixedContent"
  | "notEnoughImages"
  | "tooManyGifs"
  | "contentTooLong"
  | "invalidGMBCTA"
  | "tiktokTermsNonAgreed"
  | "tiktokMissingCommercialContentDisclosure"
  | "missingSurface"
  | "missingScheduleTime"
  | "tooManyAccounts";

export type DiagnosticErrorDescription = {
  msg: I18nKey,
  data: (Channel) => Object,
};

export const DIAGNOSTICS_MAP: Record<
  DiagnosticError,
  DiagnosticErrorDescription
> = {
  tooManyMedia: {
    msg: "PostCreator.maxMediaExceeded",
    data: (channel) => ({
      channel: channel.name,
      count: channel.contentSpecs.media.overall.maxCount,
    }),
  },
  notEnoughMedia: {
    msg: "PostCreator.minMediaExceeded",
    data: (channel) => ({
      channel: channel.name,
      count: channel.contentSpecs.media.overall.minCount,
    }),
  },
  tooManyVideos: {
    msg: "PostCreator.maxVideosExceeded",
    data: (channel) => ({
      channel: channel.name,
      count: channel.contentSpecs.media.video?.maxCount,
    }),
  },
  tooManyImages: {
    msg: "PostCreator.maxImagesExceeded",
    data: (channel) => ({
      channel: channel.name,
      count: channel.contentSpecs.media.image?.maxCount,
    }),
  },
  unsupportedMixedContent: {
    msg: "PostCreator.unsupportedMixedContent",
    data: (channel) => ({ channel: channel.name }),
  },
  notEnoughImages: {
    msg: "PostCreator.minImagesExceeded",
    data: (channel) => ({
      channel: channel.name,
      count: channel.contentSpecs.media.image?.minCount,
    }),
  },
  tooManyGifs: {
    msg: "PostCreator.maxGifExceeded",
    data: (channel) => ({
      channel: channel.name,
      count: channel.contentSpecs.media.gif?.maxCount,
    }),
  },
  contentTooLong: {
    msg: "PostCreator.contentTooLong",
    data: (channel) => ({
      channel: channel.name,
      count: channel.contentSpecs.maxChars,
    }),
  },
  invalidGMBCTA: {
    msg: "PostCreator.invalidGMBCTA",
    data: () => ({}),
  },
  tiktokTermsNonAgreed: {
    msg: "PostCreator.tiktokTermsNonAgreed",
    data: () => ({}),
  },
  tiktokMissingCommercialContentDisclosure: {
    msg: "PostCreator.tiktokMissingCommercialContentDisclosure",
    data: () => ({}),
  },
  missingSurface: {
    msg: "PostCreator.missingSurface",
    data: (channel) => ({ channel: channel.name }),
  },
  missingScheduleTime: {
    msg: "PostCreator.missingScheduleTime",
    data: () => ({}),
  },
  tooManyAccounts: {
    msg: "PostCreator.tooManyAccounts",
    data: () => ({}),
  },
};

export const getFirstDiagnosticErrorDescription = (
  errors: Array<DiagnosticError>
): ?DiagnosticErrorDescription =>
  errors.length > 0 ? DIAGNOSTICS_MAP[errors[0]] : undefined;

export const getDiagnosticErrorDescriptions = (
  errors: Array<DiagnosticError>
): DiagnosticErrorDescription[] => values(pick(DIAGNOSTICS_MAP, errors));
