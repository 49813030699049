// @flow
import * as React from "react";
import moment from "moment";
import "moment-timezone";
import { momentLocalizer } from "react-big-calendar";
import "moment/locale/en-gb";
import PreviousIcon from "@mui/icons-material/SkipPrevious";
import NextIcon from "@mui/icons-material/SkipNext";
import WeekIcon from "@mui/icons-material/ViewWeek";
import MonthIcon from "@mui/icons-material/ViewComfy";
import TodayIcon from "@mui/icons-material/Today";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import useIsMobile from "../../../../hooks/useIsMobile";
import type {
  CalendarPost,
  CalendarView,
} from "../../../../util/calendar.util";
import DragAndDropCalendar from "./DragAndDropCalendar";
import {
  getPostSnippet,
  getTime,
  isPublished,
} from "../../../../models/post.model";
import Translate from "../../../lib/display/Translate";
import { darken, lighten, useTheme } from "@mui/material";
import { Caption } from "../../../lib/display/Text";
import isEmpty from "lodash/isEmpty";
import ChannelIcon from "../../../lib/display/icons/ChannelIcon";
import { styled } from "@mui/material/styles";
import type { Theme } from "../../../../stubs/mui/theming";

moment.locale("en-gb");
const localizer = momentLocalizer(moment);

const getPostStyle = (post: CalendarPost, theme: Theme) => {
  const colorBase = !isEmpty(post.portfolio.color)
    ? post.portfolio.color
    : theme.palette.primary.main;

  const [bgFn, clFn] =
    theme.palette.mode === "light" ? [lighten, darken] : [darken, lighten];
  const colors = {
    backgroundColor: bgFn(colorBase, 0.6),
    color: clFn(colorBase, 0.6),
  };

  return { style: { transition: "top ease 0.4s, left ease 0.4s", ...colors } };
};

type EventProps = {
  view: CalendarView,
  post: CalendarPost,
};

const EventChannelsRoot = styled("div")({
  justifyContent: "flex-end",
  display: "flex",
  "&.week": {
    position: "absolute",
    right: 0,
    top: 0,
  },
});

const EventChannels: React.ComponentType<EventProps> = ({ post, view }) => (
  <EventChannelsRoot className={view}>
    {post.channels.map((channel) => (
      <ChannelIcon key={channel} channel={channel} className="rbc-event-icon" />
    ))}
  </EventChannelsRoot>
);

const EventTitleRoot = styled("div")({
  maxHeight: 46,
});

const EventTitleHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

const EventTitleTime = styled(Caption)(({ theme }) => ({
  paddingLeft: theme.spacing(0.5),
}));

const EventTitleContent = styled(Caption)(({ theme }) => ({
  overflow: "hidden",
  display: "block",
  textOverflow: "ellipsis",
  padding: 2,
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(0.5),
  width: "100%",
}));

const EventTitle: React.ComponentType<EventProps> = React.memo(
  ({ post, view }) => {
    const time = getTime(post);
    return (
      <EventTitleRoot>
        <EventTitleHeader>
          {view === "month" && time && (
            <EventTitleTime fontWeight="bold">
              {time.format("LT")}
            </EventTitleTime>
          )}
          <EventChannels post={post} view={view} />
        </EventTitleHeader>
        <EventTitleContent>{getPostSnippet(post)}</EventTitleContent>
      </EventTitleRoot>
    );
  }
);

const makeTitleAccessor = (view: CalendarView) => (post: CalendarPost) =>
  <EventTitle post={post} view={view} />;

const DEFAULT_MESSAGES = {
  date: <Translate id="CalendarSection.Calendar.messages.date" />,
  time: <Translate id="CalendarSection.Calendar.messages.time" />,
  event: <Translate id="CalendarSection.Calendar.messages.event" />,
  allDay: <Translate id="CalendarSection.Calendar.messages.allDay" />,
  week: <Translate id="CalendarSection.Calendar.messages.week" />,
  work_week: <Translate id="CalendarSection.Calendar.messages.work_week" />,
  day: <Translate id="CalendarSection.Calendar.messages.day" />,
  month: <Translate id="CalendarSection.Calendar.messages.month" />,
  previous: <Translate id="CalendarSection.Calendar.messages.previous" />,
  next: <Translate id="CalendarSection.Calendar.messages.next" />,
  yesterday: <Translate id="CalendarSection.Calendar.messages.yesterday" />,
  tomorrow: <Translate id="CalendarSection.Calendar.messages.tomorrow" />,
  today: <Translate id="CalendarSection.Calendar.messages.today" />,
  agenda: <Translate id="CalendarSection.Calendar.messages.agenda" />,
  noEventsInRange: (
    <Translate id="CalendarSection.Calendar.messages.noEventsInRange" />
  ),
};

const MOBILE_MESSAGES = {
  ...DEFAULT_MESSAGES,
  week: <WeekIcon />,
  month: <MonthIcon />,
  previous: <PreviousIcon />,
  next: <NextIcon />,
  today: <TodayIcon />,
};

const DESKTOP_MESSAGES = {
  ...DEFAULT_MESSAGES,
  previous: <PreviousIcon />,
  next: <NextIcon />,
};

type Props = {
  posts: CalendarPost[],
  onMovePost: ({ start: Date, event: CalendarPost }) => any,
  onPostDblClick: (post: CalendarPost) => any,
  view: CalendarView,
  onChangeView: (CalendarView) => any,
};

const Calendar: React.ComponentType<Props> = ({
  posts,
  onMovePost,
  onPostDblClick,
  view,
  onChangeView,
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const messages = isMobile ? MOBILE_MESSAGES : DESKTOP_MESSAGES;
  const eventPropGetter = React.useCallback(
    (post: CalendarPost) => getPostStyle(post, theme),
    [theme]
  );
  const titleAccessor = React.useMemo(() => makeTitleAccessor(view), [view]);

  return (
    <DragAndDropCalendar
      popup
      view={view}
      onView={onChangeView}
      step={30}
      showAllEvents
      views={["month", "week"]}
      localizer={localizer}
      events={posts}
      eventPropGetter={eventPropGetter}
      titleAccessor={titleAccessor}
      startAccessor="start"
      endAccessor="end"
      formats={{
        eventTimeRangeFormat: ({ start }, culture, localizer) =>
          localizer.format(start, "LT", culture),
      }}
      messages={messages}
      resizable={false}
      onEventDrop={onMovePost}
      onDoubleClickEvent={onPostDblClick}
      draggableAccessor={(event) => onMovePost && !isPublished(event)}
    />
  );
};

export default Calendar;
