// @flow
import * as React from "react";
import usePostValidator from "./usePostValidator";
import PostBuilderContext from "../../contexts/postBuilder";
import { useAccount } from "../../store/selectors";
import { useStoreActions } from "../../store/store";
import * as postService from "../../services/post.service";
import {
  makeAddPostParamsFromPost,
  makeEditPostParamsFromPost,
} from "../../services/post.service";
import type { Callback } from "../../types";
import { getAccountChannel } from "../../models/account.model";
import { applyTimezone } from "../../models/timezone.model";
import instagram from "../../models/channels/instagram.model";
import { canDisableAutoPublish } from "../../models/contentTypes.model";
import type { AddPostInput } from "../../services/post.service";
import { normalisePoll } from "../../models/poll.model";
import { isSelfReplyNotEmpty } from "../../models/post.model";

type PublishHandler = (Callback, ?boolean) => Promise<any>;

const usePublishHandler = (): PublishHandler => {
  const validatePost = usePostValidator();
  const { post } = React.useContext(PostBuilderContext);
  const account = useAccount(post.account_id);
  const actions = useStoreActions();

  return React.useCallback(
    async (onSuccess: Callback, forceDraft = false) => {
      // Perform checks
      if (account && validatePost()) {
        const channel = getAccountChannel(account);
        const params: AddPostInput = {
          ...post,
          channel: channel.slug,
          status: forceDraft ? "draft" : post.status,
          poll: normalisePoll(post.poll),
          self_replies: post.self_replies.filter(isSelfReplyNotEmpty),

          // For some channels, a poll means no attachments.
          attachments:
            post.poll && channel.features?.content?.polls?.pollsOrAttachments
              ? []
              : post.attachments,

          // If provided, apply the timezone.
          time:
            post.status === "published"
              ? null
              : applyTimezone(post.schedule_time, post.local_schedule_tz_id),
          timezone_id: post.local_schedule_tz_id,
          first_comment: !!channel.features?.commentManagement
            ? post.first_comment
            : "",

          // thumbnail_video_offset can only be set for single video posts.
          thumbnail_video_offset:
            post.attachments.length >= 1 && post.attachments[0].type === "video"
              ? post.thumbnail_video_offset
              : null,
          extras: post.extras,
          disable_autopublish:
            channel.slug === instagram.slug &&
            canDisableAutoPublish(post.content_type)
              ? post.disable_autopublish
              : undefined,
        };

        if (post.id) {
          postService.editPost(actions)(
            await makeEditPostParamsFromPost(
              { ...params, id: post.id },
              actions
            ),
            onSuccess
          );
        } else {
          postService.addPost(actions)(
            await makeAddPostParamsFromPost(params, actions),
            onSuccess
          );
        }
      }
    },
    [actions, post, validatePost, account]
  );
};

export default usePublishHandler;
