// @flow
import * as React from "react";
import { Collapse, List } from "@mui/material";
import APISearch from "../../../inputs/APISearch";
import {
  SubchannelAdornment,
  TargetSpecAdornment,
} from "../../../display/Adornments";
import type { SubChannel } from "../../../../models/subChannel.model";
import type { TargetSpecs } from "./types";
import type { TFunction } from "../../../../hooks/useTranslate";
import useTranslate from "../../../../hooks/useTranslate";
import ChannelIcon from "../../../lib/display/icons/ChannelIcon";
import { getAccountEntityName } from "../../../../models/account.model";
import { QuestionMarkRounded } from "@mui/icons-material";
import StdListItem, {
  CollapsibleStdListItem,
} from "../../../lib/display/listItems";
import type { ModelID } from "../../../../types";
import PostCreatorSection from "../../../layout/PostCreatorSection";
import Autocomplete from "../../../lib/inputs/Autocomplete";

export type Props = {
  targets: TargetSpecs[],
  selected: ?TargetSpecs,
  onSelect: (TargetSpecs) => any,
  subchannel: ?SubChannel,
  onSelectSubchannel: (SubChannel) => any,
};

type ItemCommonProps = {
  dense: true,
  icon?: ?React.Node,
  avatar?: ?React.Node,
  primary: string,
  secondary: ?string,
};

type TargetPrimaryButtonProps = {
  options: TargetSpecs[],
  selected?: ?TargetSpecs,
  onSelectTarget: (TargetSpecs, ?ModelID) => any,
};

const getTargetListItemProps = (
  target: TargetSpecs,
  t: TFunction
): ItemCommonProps => ({
  dense: true,
  icon: <ChannelIcon channel={target.account.channel} color="channel" />,
  primary: getAccountEntityName(target.account),
  secondary: t(target.label),
});

const getNoTargetListItemProps = (t: TFunction): ItemCommonProps => ({
  dense: true,
  icon: <QuestionMarkRounded />,
  primary: t("SelectPostTargetWidget.primaryItemEmpty.primary"),
  secondary: t("SelectPostTargetWidget.primaryItemEmpty.secondary"),
});

const TargetPrimaryButton: React.ComponentType<TargetPrimaryButtonProps> =
  React.memo(({ options, onSelectTarget, selected }) => {
    const t = useTranslate();
    const commonProps = selected
      ? getTargetListItemProps(selected, t)
      : getNoTargetListItemProps(t);
    return (
      <CollapsibleStdListItem {...commonProps} collapseOnItemClick>
        <List>
          {options.map((target) => (
            <StdListItem
              {...getTargetListItemProps(target, t)}
              onClick={() => onSelectTarget(target)}
              key={target.key}
            />
          ))}
        </List>
      </CollapsibleStdListItem>
    );
  });

const primaryAutocompleteFilterOptions = (
  options: TargetSpecs[],
  state: { inputValue: string }
): TargetSpecs[] => {
  const value = state.inputValue.toLowerCase();
  return options.filter(
    (opt) =>
      opt.account.entity_name.toLowerCase().includes(value) ||
      opt.channel.slug.includes(value)
  );
};

const getTargetSpecsKey = (opt: TargetSpecs) => opt.key;

const TargetPrimaryAutocomplete: React.ComponentType<TargetPrimaryButtonProps> =
  ({ selected, options, onSelectTarget }) => {
    const t = useTranslate();
    return (
      <Autocomplete
        sx={{ width: { xs: undefined, sm: 250 } }}
        value={selected}
        onChange={(e, value) => onSelectTarget(value)}
        optionPrimaryLabel="account.entity_name"
        optionSecondaryLabel="label"
        getOptionKey={getTargetSpecsKey}
        translateLabel
        TextFieldProps={{
          label: t("global.primaryAccount"),
          placeholder: t("SelectPostTargetWidget.primaryItemEmpty.primary"),
        }}
        options={options}
        AdornmentComponent={TargetSpecAdornment}
        filterOptions={primaryAutocompleteFilterOptions}
        adornOptions
        disableClearable
      />
    );
  };

export const CoreMainTargetWidget: React.ComponentType<Props> = ({
  selected,
  targets,
  onSelect,
  onSelectSubchannel,
  subchannel,
}) => {
  const t = useTranslate();
  return (
    <>
      {targets.length >= 10 ? (
        <TargetPrimaryAutocomplete
          options={targets}
          onSelectTarget={onSelect}
          selected={selected}
        />
      ) : (
        <TargetPrimaryButton
          options={targets}
          onSelectTarget={onSelect}
          selected={selected}
        />
      )}
      <Collapse in={!!selected?.subchannelType} unmountOnExit>
        {!!selected?.subchannelType && (
          <APISearch
            endpoint={selected.subchannelType.fetchItemsURL.replace(
              "<account_id>",
              selected.account.id.toString()
            )}
            value={subchannel}
            TextFieldProps={{
              placeholder: t(
                "SelectPostTargetWidget.subchannelInput.placeholder"
              ),
              label: t("SelectPostTargetWidget.subchannelInput.label"),
            }}
            disableClearable
            optionsAttribute="items"
            onChange={(e, value) => onSelectSubchannel(value)}
            optionPrimaryLabel="name"
            optionSecondaryLabel="description"
            adornOptions
            AdornmentComponent={SubchannelAdornment}
            once
          />
        )}
      </Collapse>
    </>
  );
};

const MainTargetWidget: React.ComponentType<Props> = (props) => {
  return (
    <PostCreatorSection title="SelectPostTargetWidget.mainAccount">
      <CoreMainTargetWidget {...props} />
    </PostCreatorSection>
  );
};

export default MainTargetWidget;
