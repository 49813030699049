import moment from "moment";
import ajax from "../../lib/ajax.lib";

const OAUTH_STORAGE_KEY = "currentOAuthAttempt";

export const OAUTH_ERROR_POPUP_BLOCKED = "popup_blocked";
export const OAUTH_ERROR_UNKNOWN = "unknown";
/** The user cancelled the box. */
export const OAUTH_ERROR_CANCELLED = "cancelled";
/** The user did not give enough permissions. */
export const OAUTH_ERROR_DENIED = "denied";

/**
 * Begins an OAuth2 workflow.
 *
 * The target OAuth URL is opened in a new window. communication
 * with that window takes place using `localStorage`, as both
 * window.opener and window.sendPostMessage are not reliable in
 * some context (the contact can be lost if some headers are returned
 * by the provider's server, as is the case for Twitter OAuth2).
 *
 * The resulting promise can either resolve with success, be rejected
 * with a failure code or just never resolve in case the login window
 * is closed by the user.
 *
 * Calling code should handle all cases above.
 *
 * @param signInUrl {string} The provider's sign-in URL.
 * @param popupFeatures {string} The features of the window to open.
 * @returns {Promise<object, string>} The promise to wait on.
 */
export const loginOAuth2 = (signInUrl, popupFeatures) =>
  new Promise((resolve, reject) => {
    const loginWin = window.open(signInUrl, "popUpWindow", popupFeatures);
    // User may have blocked popups. Check result and reject if necessary.
    !loginWin && reject(OAUTH_ERROR_POPUP_BLOCKED);
    window.localStorage.removeItem(OAUTH_STORAGE_KEY);
    let checkTimer = null;
    let start = moment();

    const processAuthResult = (authResult) => {
      try {
        if (authResult.cancelled) {
          reject(OAUTH_ERROR_CANCELLED);
        } else if (authResult.denied) {
          reject(OAUTH_ERROR_DENIED);
        } else if (authResult.error) {
          reject(authResult.error);
        } else {
          resolve(authResult);
        }
      } catch (e) {
        reject(e.toString());
      }
    };

    // Option 1:
    // If the child window has a handle on its parent, it should be
    // able to send a message with the result.
    const receiveMessage = (event) => {
      if (!event.data.oauth_result) {
        // Not the event we are looking for.
        return;
      }
      try {
        processAuthResult(event.data);
        cleanAndLeave();
      } catch (e) {
        console.log("bad oauth message", event.data);
      }
    };

    window.addEventListener("message", receiveMessage, false);

    const cleanAndLeave = () => {
      window.localStorage.removeItem(OAUTH_STORAGE_KEY);
      window.removeEventListener("message", receiveMessage, false);
      clearInterval(checkTimer);
      loginWin.close();
    };

    // Option 2 (polyfill)
    // Check whether the localStorage entry has been set manually.
    const checkResult = () => {
      // If it has, close the window and stop the timer.
      const raw = window.localStorage.getItem(OAUTH_STORAGE_KEY);
      raw && processAuthResult(JSON.parse(raw));
      // Close if the window is reliably closed, if a result arrived
      // or if the login times out.
      if (raw || moment().diff(start, "second", true) > 300) {
        cleanAndLeave();
      }
    };
    checkTimer = setInterval(checkResult, 1000);
  });

export const getOAuth1Token = (requestTokenUrl) =>
  new Promise((resolve, reject) => {
    ajax
      .get(requestTokenUrl)
      .then(({ oauth_token, oauth_callback_confirmed }) => {
        if (!oauth_callback_confirmed) {
          reject(OAUTH_ERROR_UNKNOWN);
        } else {
          resolve(oauth_token);
        }
      });
  });

export const loginOAuth1 = (oauth_token, signInUrl, popupFeatures) =>
  loginOAuth2(`${signInUrl}?oauth_token=${oauth_token}`, popupFeatures);
