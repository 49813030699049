// @flow
import * as React from "react";
import useAjaxErrorInterceptor from "../../hooks/useAjaxInterceptor";
import Translate from "../../components/lib/display/Translate";
import { checkEmail, checkPassword } from "../../util/validation.util";
import { API as backend } from "../../services/backend.service";
import { useStoreActions } from "../../store/store";
import { localSuccess } from "../../models/alerts.model";
import LoginWidget from "../../components/widgets/LoginWidget";
import { Center } from "../../components/lib/layout/placement";

const handleLogIn = (username: string, password: string) =>
  backend.auth.signIn({ email: username, password });

const LoginPageContainer: React.ComponentType<{}> = () => {
  useAjaxErrorInterceptor();
  const storeActions = useStoreActions();

  const handleResetPassword = (username: string) => {
    backend.auth
      .requestPasswordReset({ username })
      .then(() =>
        storeActions.snacks.append(
          localSuccess({
            message: "LoginPage.resetPasswordRequestSent",
          })
        )
      )
      .catch(console.log);
  };

  return (
    <Center>
      <LoginWidget
        onValidCredentials={handleLogIn}
        onResetPassword={handleResetPassword}
        prompt={<Translate id="LoginPage.subtitle" />}
        checkPassword={checkPassword}
        checkUsername={checkEmail}
      />
    </Center>
  );
};

export default LoginPageContainer;
