// @flow
import * as React from "react";
import PostBuilderContext from "../../../../contexts/postBuilder";
import GMBContentEditor from "./GMBContentEditor";
import PinterestContentEditor from "./PinterestContentEditor";
import TiktokContentEditor from "./TiktokContentEditor";
import googleMyBusiness from "../../../../models/channels/googleMyBusiness.model";
import pinterest from "../../../../models/channels/pinterest.model";
import tiktok from "../../../../models/channels/tiktok.model";
import { Collapse } from "@mui/material";
import { PostCreatorCollapsibleSection } from "../../../layout/PostCreatorSection";
import type { Slug } from "../../../../types";

const CONTENT_WIDGETS: Record<Slug, React.ComponentType<{}>> = {
  [googleMyBusiness.slug]: GMBContentEditor,
  [pinterest.slug]: PinterestContentEditor,
  [tiktok.slug]: TiktokContentEditor,
};

const DynamicContentWidget: React.ComponentType<{}> = () => {
  const {
    channel,
    post: { id },
  } = React.useContext(PostBuilderContext);
  const Component = channel ? CONTENT_WIDGETS[channel.slug] : undefined;

  return (
    <Collapse in={!!Component}>
      <PostCreatorCollapsibleSection
        title="global.channelSpecificContent"
        titleData={{ channel: channel?.name }}
        defaultCollapsed={!!id}
      >
        {Component ? <Component /> : null}
      </PostCreatorCollapsibleSection>
    </Collapse>
  );
};

export default DynamicContentWidget;
