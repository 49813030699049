// @flow
import * as React from "react";
import isEmpty from "lodash/isEmpty";
import words from "lodash/words";
import { Autocomplete, Chip, TextField } from "@mui/material";
import type { Props as TextFieldProps } from "../../../stubs/mui/TextField";
import type { Props as AutocompleteProps } from "../../../stubs/mui/Autocomplete";

export type Props = {
  ...Omit<AutocompleteProps, "getOptionLabel" | "renderInput" | "options">,
  TextFieldProps?: Omit<TextFieldProps, "value" | "onChange">,
  value: string[],
  onChange: (e: SyntheticEvent<>, string[]) => any,
};

const NO_OPTIONS: any[] = [];

const ChipsInput: React.ComponentType<Props> = ({
  value,
  onChange,
  TextFieldProps,
  ...props
}) => {
  const [inputValue, setInputValue] = React.useState("");
  const { placeholder, ...restTextFieldProps } = TextFieldProps ?? {};

  return (
    <Autocomplete
      multiple
      options={NO_OPTIONS}
      freeSolo
      inputValue={inputValue}
      onInputChange={(e, value) => setInputValue(value)}
      value={value}
      renderTags={(tags, getTagProps) =>
        tags.map((option, index) => (
          <Chip label={option} size="small" {...getTagProps({ index })} />
        ))
      }
      onChange={onChange}
      renderInput={(renderProps) => (
        <TextField
          {...renderProps}
          {...restTextFieldProps}
          placeholder={isEmpty(value) ? placeholder : undefined}
          InputProps={{
            ...renderProps.InputProps,
            disableUnderline: true,
            onKeyDown: (e) => {
              if (!isEmpty(e.target.value) && [" ", "Tab"].includes(e.key)) {
                onChange(e, [...value, e.target.value]);
                e.preventDefault();
                setInputValue("");
              }
            },
          }}
          onPaste={(e) => {
            e.preventDefault();
            const text = e.clipboardData.getData("Text");
            // Split the words
            // Pattern contains the separators.
            const tags = words(text, /[^#, ;:.\t]+/g);
            onChange(e, [...value, ...tags]);
          }}
        />
      )}
      {...props}
    />
  );
};

export default ChipsInput;
