// @flow
import * as React from "react";
import type { Timezone } from "../../../../models/timezone.model";
import type { Moment } from "../../../../types";
import { Body2, Caption } from "../../../lib/display/Text";
import useTranslate from "../../../../hooks/useTranslate";
import { getTimezoneDescription } from "../../../../util/timezoneOptions.util";
import Translate from "../../../lib/display/Translate";
import { ColumnStack } from "../../../lib/layout/stacks";
import ScheduleTimePicker from "../../../inputs/ScheduleTimePicker";

type Props = {
  timezone: Timezone,
  selectedDate: ?Moment,
  onDateChange: (?Moment) => any,
  onTimeChange: (?Moment) => any,
};

const PostScheduleWidget: React.ComponentType<Props> = (props) => {
  const t = useTranslate();
  return (
    <ColumnStack marginTop={1}>
      <Body2 color="textSecondary">
        <Translate id="ScheduleDialog.dateAndTimeSubtitle" />
      </Body2>
      <ScheduleTimePicker {...props} />
      <Caption color="textSecondary">
        {t(getTimezoneDescription(props.timezone))}
      </Caption>
    </ColumnStack>
  );
};

export default PostScheduleWidget;
