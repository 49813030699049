// @flow
import Sticker from "./Sticker";
import type { StickerProps } from "./Sticker";
import { styled } from "@mui/material/styles";
import type { ComponentType, CSSProps } from "../../../reactTypes";

type Props = {
  ...Pick<StickerProps, "size" | "variant" | "children" | "outline">,
  ...CSSProps,
};

const TextSticker: ComponentType<Props> = styled(Sticker)(
  ({ theme }) => `
  color: ${theme.palette.text.secondary};
  font-weight: bold;
`
);

export default TextSticker;
