// @flow
import facebook from "./facebook.model";
import instagram from "./instagram.model";
import linkedin from "./linkedin.model";
import pinterest from "./pinterest.model";
import twitter from "./twitter.model";
import googleMyBusiness from "./googleMyBusiness.model";
import tiktok from "./tiktok.model";
import compact from "lodash/compact";
import keyBy from "lodash/keyBy";
import {
  DEFAULT_IMAGE_MAX_SIZE,
  DEFAULT_VIDEO_MAX_SIZE,
  isGif,
  isImage,
  isPDF,
  isVideo,
} from "../../lib/files.lib";
import type { Channel } from "./channel.model";
import type { Bytes, Mimetype, Slug } from "../../types";
import without from "lodash/without";

/**
 * Get a list of mimetypes that the channel accepts accross all types of
 * media.
 */
export const getAccepts = (channel: ?Channel): Mimetype[] =>
  compact([
    ...(channel?.contentSpecs.media.image?.mimetypes ?? []),
    ...(channel?.contentSpecs.media.video?.mimetypes ?? []),
    ...(channel?.contentSpecs.media.document?.mimetypes ?? []),
    channel?.contentSpecs.media.image?.pngAsJpeg && "image/png",
  ]);

/**
 * Get the most appropriate max media size for the file and channel.
 * @param {Channel} channel
 */
export const getMaxSize =
  (channel: ?Channel): ((File) => Bytes) =>
  (file) =>
    isGif(file)
      ? channel?.contentSpecs.media.gif?.maxSize ?? DEFAULT_IMAGE_MAX_SIZE
      : isImage(file)
      ? channel?.contentSpecs.media.image?.maxSize ?? DEFAULT_IMAGE_MAX_SIZE
      : isVideo(file)
      ? channel?.contentSpecs.media.video?.maxSize ?? DEFAULT_VIDEO_MAX_SIZE
      : isPDF(file)
      ? channel?.contentSpecs.media.document?.maxSize ?? DEFAULT_VIDEO_MAX_SIZE
      : DEFAULT_IMAGE_MAX_SIZE;

export const allChannels: Channel[] = [
  facebook,
  linkedin,
  instagram,
  twitter,
  pinterest,
  googleMyBusiness,
  tiktok,
];

const channels: Channel[] = without(allChannels);
const channelsBySlug: Record<Slug, Channel> = keyBy(allChannels, "slug");

export const getChannel = (slug: ?Slug): ?Channel =>
  slug ? channelsBySlug[slug] : null;

export default channels;
