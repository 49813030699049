// @flow
import ajax from "../../lib/ajax.lib";
import type { DataURI, Integer, ModelID, Timestamp } from "../../types";
import type { Poll } from "../../models/poll.model";
import type { APIPost, Post, PostStatus } from "../../models/post.model";
import { fromAPIResult } from "../../models/post.model";
import type {
  AttachmentTypes,
  AttachmentUserTag,
} from "../../models/attachment.model";
import type { APIResult, Pagination } from "./types";
import type { ContentTypes } from "../../models/contentTypes.model";

export type MediaParams = {
  type: AttachmentTypes,
  data: string,
  is_ref: boolean,
  user_tags?: AttachmentUserTag[],
};

type SelfReplyParams = {
  content: ?string,
  media: MediaParams[],
};

export type ReschedulePostParams = {
  id: ModelID,
  schedule_time: Timestamp,
};

export type BulkReschedulePostsParams = {
  posts: ReschedulePostParams[],
};

export type AddPostParams = {
  // _CorePostParams
  content: string,
  status: PostStatus,
  account_id: ModelID,
  time: ?Timestamp,

  // PostAttachmentParams
  media: MediaParams[],

  // Post thumbnail params
  thumbnail_image?: ?DataURI,
  thumbnail_title?: ?string,
  thumbnail_video_offset?: ?Integer,

  // Threads
  self_replies: SelfReplyParams[],

  // PostParams
  title?: ?string,
  timezone_id?: ?ModelID,

  first_comment?: ?string,
  extras?: ?Object,
  link?: ?string,
  poll?: ?Poll,

  // Content type and subchannel, to manage target and surface
  subchannel_id?: ?ModelID,
  content_type: ContentTypes,
  disable_autopublish?: ?boolean,

  // Multi channel target
  secondary_account_ids: ModelID[],

  // metrics
  via?: "VizPlanner" | "Creator",
};

export type EditPostParams = {
  ...AddPostParams,
  id: ModelID,
};

export const addPost = (params: AddPostParams): Promise<Post> =>
  ajax
    .post<AddPostParams, APIResult<APIPost>>("/post", params, {
      disableErrorInterceptor: true,
    })
    .then((response) => fromAPIResult(response.data.data));

export const editPost = ({ id, ...params }: EditPostParams): Promise<Post> =>
  ajax
    .put<AddPostParams, APIResult<APIPost>>(`/post/${id}`, params, {
      disableErrorInterceptor: true,
    })
    .then((response) => fromAPIResult(response.data.data));

export const deletePost = (
  postId: ModelID,
  permanently: boolean = false
): Promise<Post> =>
  ajax
    .delete<APIResult<APIPost>>(`/post/${postId}?hard=${permanently ? 1 : 0}`)
    .then((response) => fromAPIResult(response.data.data));

export const cancelDeletePost = (postId: ModelID): Promise<Post> =>
  ajax
    .put<void, APIResult<APIPost>>(`/post/${postId}/cancel_delete`)
    .then((response) => fromAPIResult(response.data.data));

export const duplicatePost = (postId: ModelID): Promise<Post> =>
  ajax
    .post<void, APIResult<APIPost>>(`/post/${postId}/duplicate`)
    .then((response) => fromAPIResult(response.data.data));

export const markPostAsPublished = (postId: ModelID): Promise<Post> =>
  ajax
    .put<void, APIResult<APIPost>>(`/post/${postId}/published`)
    .then((response) => fromAPIResult(response.data.data));

export type GetPortfolioPostsParams = {
  ...Pagination,
  portfolioId: ModelID,
};

export const getPostsByIds = (ids: ModelID[]): Promise<Post[]> =>
  ajax
    .get<APIResult<APIPost[]>>(`/post?ids=${ids.toString()}`)
    .then((resp) => resp.data.data.map(fromAPIResult));

export const getPortfolioPosts = ({
  page = 1,
  pagesize = 20,
  portfolioId,
}: GetPortfolioPostsParams): Promise<Post[]> =>
  ajax
    .get<APIResult<APIPost[]>>(
      `/portfolio/${portfolioId}/posts?page=${page}&pagesize=${pagesize}`
    )
    .then((resp) => resp.data.data.map(fromAPIResult));

export const reschedulePosts = (
  params: BulkReschedulePostsParams
): Promise<Post[]> =>
  ajax
    .put<BulkReschedulePostsParams, APIResult<APIPost[]>>(
      `/post/reschedule`,
      params
    )
    .then((response) => response.data.data.map(fromAPIResult));
