// @flow
import * as React from "react";
import VideoThumbnailPickerDialog from "./VideoThumbnailPickerDialog";
import useObjectURL from "../../../hooks/useObjectURL";
import type { AttachmentSrc } from "../../../models/attachment.model";
import type { Callback, Milliseconds } from "../../../types";

type Props = {
  video: AttachmentSrc,
  onClose: Callback,
  defaultOffset: ?Milliseconds,
  onValidate: (Milliseconds) => any,
};

const VideoThumbnailPickerDialogContainer: React.ComponentType<Props> = ({
  video,
  onClose,
  defaultOffset,
  onValidate,
}) => {
  const [offset, setOffset] = React.useState(defaultOffset ?? 0);
  const [videoDuration, setVideoDuration] = React.useState(0);
  const videoRef = React.useRef(null);
  const videoURL = useObjectURL(video);

  const handleChangeOffset = React.useCallback((val: number) => {
    setOffset(val);
    if (videoRef.current) {
      videoRef.current.currentTime = val / 1000;
    }
  }, []);

  const handledLoadedMetadata = React.useCallback(
    (e: SyntheticEvent<HTMLVideoElement>) => {
      setVideoDuration(e.currentTarget.duration * 1000);
      if (defaultOffset) {
        handleChangeOffset(defaultOffset);
      }
    },
    [defaultOffset, handleChangeOffset]
  );

  return (
    <VideoThumbnailPickerDialog
      offset={offset}
      onChangeOffset={handleChangeOffset}
      videoDuration={videoDuration}
      onLoadedMetadata={handledLoadedMetadata}
      video={videoURL}
      videoRef={videoRef}
      onClose={onClose}
      onValidate={() => onValidate(offset)}
    />
  );
};

export default VideoThumbnailPickerDialogContainer;
