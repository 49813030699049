import { MBYTE } from "../../lib/files.lib";
import type { Channel } from "./channel.model";
import {
  login,
  wasLoginCancelled,
} from "../../services/channels/instagram.service";

const instagram: Channel = {
  name: "Instagram",
  slug: "instagram",
  color: "#BC2A9E",

  features: {
    devicePublish: true,
    businessAccount: true,
    liveEdit: {},
    commentManagement: {
      edit: true,
      likes: false,
    },
    analytics: {
      post: true,
    },
    messaging: true,
    mediaTagging: {
      image: true,
    },
    content: {
      thumbnail: {
        video: true,
      },
    },
  },
  subchannelTypes: [],
  surfaces: [
    { slug: "ig_post", contentType: "post" },
    {
      slug: "ig_story",
      contentType: "story",
      noCaptionOnAutopublish: true,
    },
    { slug: "ig_reel", contentType: "reel" },
  ],
  contentSpecs: {
    maxChars: 2200,
    media: {
      image: {
        mimetypes: ["image/jpeg"],
        pngAsJpeg: true,
        maxSize: 8 * MBYTE,
        maxCount: 10,
      },
      // no gif support
      video: {
        maxCount: 10,
        maxSize: 100 * MBYTE,
        mimetypes: ["video/mp4", "video/mov", "video/quicktime"],
      },
      overall: {
        minCount: 1,
        maxCount: 10,
      },
    },
  },
  wasLoginCancelled,
  login,
};

export default instagram;
