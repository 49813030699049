// @flow
import useAttachmentsDiagnostic from "./useAttachmentsDiagnostic";
import useContentDiagnostic from "./useContentDiagnostic";
import useTargetDiagnostics from "./useTargetDiagnostics";
import usePublishDiagnostics from "./usePublishDiagnostics";
import type { Channel } from "../../models/channels/channel.model";
import type { DiagnosticError } from "../../util/diagnostics.util";

const usePostDiagnostic = (channel: ?Channel): DiagnosticError[] => {
  const attachmentDiagnostics = useAttachmentsDiagnostic(channel);
  const contentDiagnostics = useContentDiagnostic(channel);
  const targetDiagnostics = useTargetDiagnostics(channel);
  const publishDiagnostics = usePublishDiagnostics();
  return [
    ...attachmentDiagnostics,
    ...contentDiagnostics,
    ...targetDiagnostics,
    ...publishDiagnostics,
  ];
};

export default usePostDiagnostic;
