// @flow
import numeral from "numeral";
import { fileToImageDataURL, getImageSize } from "./images.lib";
import head from "lodash/head";
import initial from "lodash/initial";
import isEmpty from "lodash/isEmpty";
import last from "lodash/last";
import { lookup } from "mime-types";
import FileSaver from "file-saver";
import type { Bytes, DataURI, Ext, Mimetype, Pixels, URLStr } from "../types";
import type {
  AttachmentSrc,
  AttachmentTypes,
} from "../models/attachment.model";
import { cast } from "../types";

type FileValidator = (File) => Promise<File>;
export type MaxSizeArg = ((File) => Bytes) | Bytes;
export type MimeTypeType = "image" | "video" | "audio" | "application" | string;

export const KBYTE: Bytes = 1000;
export const MBYTE: Bytes = 1000 * KBYTE;
export const GBYTE: Bytes = 1000 * MBYTE;

export const DEFAULT_VIDEO_MAX_SIZE: Bytes = 300 * MBYTE;
export const DEFAULT_IMAGE_MAX_SIZE: Bytes = 10 * MBYTE;

export const RECOMMENDED_IMAGE_MAX_WIDTH: Pixels = 1200; // px
export const RECOMMENDED_IMAGE_MAX_HEIGHT: Pixels = 1200; // px
export const MAX_IMAGE_WIDTH: Pixels = 4096; // px
export const MAX_IMAGE_HEIGHT: Pixels = 4096; // px

export const IMAGE_RESIZING_FACTOR: number = 1.25; // Because we want to ensure some quality.

/**
 * Default image types accepted by the platform.
 * @type {string[]}
 */
export const DEFAULT_ACCEPT_IMAGES: Mimetype[] = [
  "image/png",
  "image/jpeg",
  "image/gif",
];
export const DEFAULT_ACCEPT_VIDEOS: Mimetype[] = ["video/*"];
export const DEFAULT_ACCEPT_FILES: Mimetype[] = ["*"];
export const DEFAULT_ACCEPT_PDF: Mimetype[] = ["application/pdf"];

export class FileException {
  message: string;
  data: mixed;

  constructor(message: string, data: mixed) {
    this.message = message;
    this.data = data;
  }
}

export const getDefaultAccept = (type: ?AttachmentTypes): Mimetype[] =>
  type === "image"
    ? DEFAULT_ACCEPT_IMAGES
    : type === "video"
    ? DEFAULT_ACCEPT_VIDEOS
    : type === "pdf"
    ? DEFAULT_ACCEPT_PDF
    : DEFAULT_ACCEPT_FILES;

const isImageType = (type: Mimetype) =>
  !isEmpty(type) && type.startsWith("image/");
const isGifType = (type: Mimetype) => !isEmpty(type) && type.endsWith("/gif");
const isVideoType = (type: Mimetype) =>
  !isEmpty(type) && type.startsWith("video/");
const isPDFType = (type: Mimetype) => !isEmpty(type) && type.endsWith("/pdf");
const isAudioType = (type: Mimetype) =>
  !isEmpty(type) && type.startsWith("audio/");

export const getFileOrURLMimeType = (src: ?AttachmentSrc): Mimetype => {
  if (!src) return "";
  if (src instanceof File) return src.type;
  try {
    return lookup(new URL(src).pathname);
  } catch (e) {
    return "";
  }
};
export const isImage = (fileOrURL: AttachmentSrc): boolean =>
  isImageType(getFileOrURLMimeType(fileOrURL));
export const isGif = (fileOrURL: AttachmentSrc): boolean =>
  isGifType(getFileOrURLMimeType(fileOrURL));
export const isVideo = (fileOrURL: AttachmentSrc): boolean =>
  isVideoType(getFileOrURLMimeType(fileOrURL));
export const isPDF = (fileOrURL: AttachmentSrc): boolean =>
  isPDFType(getFileOrURLMimeType(fileOrURL));
export const isAudio = (fileOrURL: AttachmentSrc): boolean =>
  isAudioType(getFileOrURLMimeType(fileOrURL));
export const isDataURL = (url: URLStr): boolean => url.startsWith("data:");

/**
 * Display a byte size in a pretty way.
 * @param size {Number} The size in bytes.
 * @param precision {string} The precision to use.
 */
export const prettySize = (size: Bytes, precision: string = ".00"): string =>
  numeral(size).format(`0${precision}b`);

export const validateSize =
  (maxSize: MaxSizeArg): FileValidator =>
  async (file) => {
    const maxSizeEffective =
      typeof maxSize === "function" ? maxSize(file) : maxSize;
    if (file.size > maxSizeEffective) {
      throw new FileException("lib.files.fileTooBig", {
        name: file.name,
        size: prettySize(file.size),
        maxSize: prettySize(maxSizeEffective),
      });
    }
    return file;
  };

export const validateType =
  (validTypes: Mimetype[]): FileValidator =>
  (file) => {
    const [type, subtype] = file.type.split("/");
    for (let validTypeItem of validTypes) {
      if (validTypeItem === "*") {
        // wildcard. All accepted.
        return Promise.resolve(file);
      }
      const [validType, validSubType] = validTypeItem.split("/");
      if (
        type === validType &&
        (subtype === validSubType || validSubType === "*")
      ) {
        return Promise.resolve(file);
      }
    }
    throw new FileException("lib.files.badFileType", { name: file.name });
  };

export const validateDimensions: FileValidator = (file: File) =>
  new Promise((resolve, reject) => {
    if (!isImage(file)) {
      resolve(file);
      return;
    }
    getImageSize(file).then(({ width, height }) => {
      if (width > MAX_IMAGE_WIDTH || height > MAX_IMAGE_HEIGHT) {
        reject(
          new FileException("lib.files.badImageDimensions", {
            name: file.name,
            width: MAX_IMAGE_WIDTH,
            height: MAX_IMAGE_HEIGHT,
          })
        );
      } else {
        resolve(file);
      }
    });
  });

/**
 * Format a list of mimetypes to be readable.
 * @returns {string}
 */
export const prettyMimeTypes = (formats: Mimetype[]): string =>
  formats.includes("image/*") || formats.includes("video/*")
    ? "Any"
    : formats.join(", ").replaceAll(/(image\/)|(video\/)/g, "");

export const downloadData = (dataBlob: Blob, filename: string) => {
  FileSaver.saveAs(dataBlob, filename);
};

export const blobToFile =
  (name: string): ((Blob) => File) =>
  (blob) =>
    new File([blob], name, { type: blob.type });

const getDataURLMimeType = (url: DataURI): Mimetype =>
  // data:[<mediatype>][;base64],<data>
  url.split(",")[0].split(":")[1].split(";")[0];

export const byteStringToBlob = (
  byteString: string,
  mimeType: Mimetype
): Blob => {
  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeType });
};

export const dataURLToBlob = (dataURL: DataURI): Blob =>
  byteStringToBlob(
    // convert base64 to raw binary data held in a string
    atob(dataURL.split(",")[1]),
    getDataURLMimeType(dataURL)
  );

export const dataURLToFile = (dataURL: DataURI, name: string): File =>
  blobToFile(name)(dataURLToBlob(dataURL));

export const getExtension = (filename: string): Ext => {
  const lastChunk = head(last(filename.split(".")).split("?"));
  // Beware of files without extension.
  return lastChunk.length > 6 ? "" : lastChunk;
};

/**
 *
 * @param name {string}
 * @param ext {string}
 * @return {string}
 */
const changeExtTo = (name: string, ext: Ext) =>
  [...initial(name.split(".")), ext].join(".");

export const toJpegImageFile = (file: File): Promise<File> =>
  fileToImageDataURL(file).then((url) =>
    dataURLToFile(url, changeExtTo(file.name, "jpg"))
  );

const ATTACHMENT_TYPE_TESTS: [AttachmentTypes, (AttachmentSrc) => boolean][] = [
  ["image", isImage],
  ["video", isVideo],
  ["audio", isAudio],
  ["pdf", isPDF],
];

const _getFileOrURLType = (fileOrUrl: AttachmentSrc): AttachmentTypes =>
  ATTACHMENT_TYPE_TESTS.find(([, test]) => test(fileOrUrl))?.[0] ?? "file";
export const getFileType = _getFileOrURLType;
export const getUrlType = _getFileOrURLType;

export const getMimetypeType = (mimetype: ?Mimetype): MimeTypeType =>
  (mimetype ?? "").split("/")[0];

/**
 *
 * @param blob {Blob}
 * @returns {Promise<String>}
 */
export const blobToString = (blob: Blob): Promise<string> =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => resolve(cast<string>(reader.result));
    reader.readAsText(blob);
  });

export const blobToDataURL = (blob: Blob): Promise<DataURI> =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => resolve(cast<string>(reader.result));
    reader.readAsDataURL(blob);
  });

export const urlToDataURL = (url: URLStr): Promise<DataURI> =>
  fetch(url)
    .then((r) => r.blob())
    .then(blobToDataURL);
