// @flow
import range from "lodash/range";
import type { Pagination } from "./backend.service/types";
import type { LockActions } from "../store/reducers/lib/lock.state";

type SupportsLocking = { ...LockActions, ... };

export const paginateRequest = async <Params, ResultItems: Array<any>>(
  request: ({ ...Params, ...Pagination }) => Promise<ResultItems>,
  onFirstPage: (ResultItems) => any,
  onOtherPage: (ResultItems) => any,
  basePayload: Params,
  pages: number = 10,
  pagesize: number = 20,
  locker?: SupportsLocking
) => {
  locker?.lock();
  try {
    onFirstPage(await request({ ...basePayload, page: 1, pagesize }));
  } finally {
    locker?.unlock();
  }
  for (const page of range(2, pages + 1)) {
    const result = await request({ ...basePayload, page, pagesize });
    if (result.length === 0) {
      return;
    }
    onOtherPage(result);
  }
};
