// @flow
import * as React from "react";
import MiniPostsCalendarWidget from "./MiniPostsCalendarWidget";
import type { Post } from "../../../models/post.model";
import { getTime, hasTime } from "../../../models/post.model";
import type { Moment } from "../../../types";
import { cast } from "../../../types";
import countBy from "lodash/countBy";
import maxBy from "lodash/maxBy";
import minBy from "lodash/minBy";
import moment from "moment";
import { getAnchorStartOfTargetMonth } from "../../../util/calendar.util";

type Props = {
  posts: Post[],
  onDayClick?: (Moment) => any,
  view?: "week" | "month",
  anchorToNow?: boolean,
};

const MiniPostsCalendarWidgetContainer: React.ComponentType<Props> = ({
  posts,
  onDayClick,
  view = "week",
  anchorToNow,
}) => {
  const lb = React.useMemo(() => getTime(minBy(posts, getTime)), [posts]);
  // The upper bound is the begining of the period of the latest post.
  // once you have crossed it there is nothing after.
  const ub = React.useMemo(
    () =>
      (getTime(maxBy(posts, getTime)) ?? moment())
        .startOf(view)
        .startOf("week"),
    [posts, view]
  );
  // the anchor is the start of week of the start period, so that
  // even in monthly view we start on a monday.
  const [anchor, setAnchor] = React.useState<Moment>(
    (anchorToNow ? moment() : lb ?? moment()).startOf(view).startOf("week")
  );

  // Posts are loaded progressively. Set appropriate anchor once they are all loaded.
  // Unless a static anchor was provided.
  React.useEffect(() => {
    !anchorToNow && lb && setAnchor(lb.startOf(view).startOf("week"));
  }, [anchorToNow, lb, view]);

  const badges: { [string]: number } = React.useMemo(
    () =>
      countBy(
        posts
          .filter(hasTime)
          .map(getTime)
          .map((m) => cast<Moment>(m).startOf("day").unix().toString())
      ),
    [posts]
  );

  const handlePreviousPage = React.useCallback(() => {
    if (view === "week") {
      setAnchor((prev) => moment(prev).subtract(1, "week"));
    } else {
      // can't just remove a month, that won't fall on a monday.
      setAnchor((prev) => {
        const currentMonth = getAnchorStartOfTargetMonth(prev);
        return currentMonth.subtract(1, "month").startOf("week");
      });
    }
  }, [view]);
  const handleNextPage = React.useCallback(() => {
    if (view === "week") {
      setAnchor((prev) => moment(prev).add(1, view));
    } else {
      // can't just remove a month, that won't fall on a monday.
      setAnchor((prev) => {
        const currentMonth = getAnchorStartOfTargetMonth(prev);
        return currentMonth.add(1, "month").startOf("week");
      });
    }
  }, [view]);

  return (
    <MiniPostsCalendarWidget
      anchor={anchor}
      onPreviousPage={anchor.isAfter(lb) ? handlePreviousPage : undefined}
      onNextPage={anchor.isBefore(ub) ? handleNextPage : undefined}
      getBadge={(day) => !!badges[day.unix().toString()]}
      onDayClick={onDayClick}
      view={view}
    />
  );
};

export default MiniPostsCalendarWidgetContainer;
